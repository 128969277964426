<template>
  <validation-observer v-slot="validation">
    <div class="overlay" @click="flyout = false"></div>
    <div class="container sticky-header">
      <div class="content">
        <h2>Request Resubmission</h2>
        <div class="document-toolbar">
          <div class="document-actions">
            <div class="button-group">
              <button :disabled="validation.invalid || quillEmpty" class="button" @click="submit">Send Request</button><a class="button secondary" @click="$router.go(-1)">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main class="off-white">
      <div class="container">
        <div class="content">
          <h4>{{ documentTitle }}</h4>
          <p>
            All partners at client that have signed this document will be sent a text and email that will request them to resign this document. Partners that have different versions of this agreement
            will not be sent the request to resign.
          </p>
          <div class="tabs">
            <ul class="list-unstyled tab-list">
              <li :class="{ active: tab === 'first-tab' }" @click="tab = 'first-tab'">Email Message</li>
              <li :class="{ active: tab === 'text-message' }" @click="tab = 'text-message'">Text Message</li>
            </ul>
            <div class="tab-content">
              <div v-show="tab === 'first-tab'">
                <h5>Email Message</h5>
                <div class="grid">
                  <div class="col-10_md-12">
                    <EditorToolbar />
                  </div>
                  <div class="col-4_md-6_sm-12">
                    <div class="field">
                      <form-input v-model="subject" name="subject" label="Subject" required placeholder="Email Subject" />
                    </div>
                  </div>
                  <div class="col-10_md-12">
                    <Editor v-model="emailText" @emptyStatus="isQuillEmpty" />
                  </div>
                </div>
                <div class="button-group close-top">
                  <button :disabled="validation.invalid || quillEmpty" class="button" @click="submit">Send Request</button><a class="button secondary" @click="$router.back()">Cancel</a>
                </div>
              </div>
              <div v-show="tab === 'text-message'">
                <h5>Text Message</h5>
                <div class="grid">
                  <div class="col-6_md-12">
                    <validation-provider v-slot="{ errors }" name="Text Field" rules="required">
                      <textarea v-model="smsText" maxlength="160" />
                      <span>{{ charsUsed }}/160</span>
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </div>
                <div class="button-group close-top">
                  <button :disabled="validation.invalid || quillEmpty" class="button" @click="submit">Send Request</button><a class="button secondary" @click="$router.back()">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import EditorToolbar from "@/components/editor/EditorToolbar";
import Editor from "@/components/editor/Editor";
import Footer from "@/components/layout/Footer";
import { GET_DOCUMENT_PARTNER_INFO } from "@/modules/shared/documents/graph/queries";
import { SEND_EMAIL, SEND_SMS } from "@/modules/admin/clients/graph/mutations";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import FormInput from "@/components/forms/fields/FormInput";
export default {
  name: "RequestResubmission",
  title: "Request Resubmission",
  components: { FormInput, Footer, Editor, EditorToolbar, ValidationObserver, ValidationProvider },
  props: {
    documentID: {
      type: String,
      required: true,
      default: "",
    },
  },
  data: () => {
    return {
      tab: "first-tab",
      documentTitle: "Title goes here",
      emailText: {},
      smsText: "",
      subject: "",
      partners: [],
      quillEmpty: true,
      from: "mlanders@ddiwork.com",
    };
  },
  computed: {
    charsUsed() {
      return this.smsText.length;
    },
    createHTML() {
      const cfg = {};
      if (this.emailText.ops === undefined) {
        return "";
      } else {
        const converter = new QuillDeltaToHtmlConverter(this.emailText.ops, cfg);
        return converter.convert();
      }
    },
  },
  methods: {
    isQuillEmpty(status) {
      this.quillEmpty = status;
    },
    async submit() {
      await this.sendText();
      await this.sendEmail();
      this.$router.back();
    },
    sendText() {
      const userID = this.$store.getters.getUserID;
      const objects = this.partners.map((partner) => ({ to: partner.phone, body: this.smsText, partnerID: partner.id, userID }));
      return this.$apollo.mutate({
        mutation: SEND_SMS,
        variables: {
          objects,
        },
      });
    },
    sendEmail() {
      const userID = this.$store.getters.getUserID;
      const objects = this.partners.map((partner) => ({
        userID,
        from: this.from,
        html: this.createHTML,
        partnerID: partner.id,
        subject: this.subject,
        to: partner.email,
      }));
      return this.$apollo
        .mutate({
          mutation: SEND_EMAIL,
          variables: {
            objects,
          },
        })
        .catch((err) => this.$log.error(err));
    },
  },
  apollo: {
    documents: {
      query: GET_DOCUMENT_PARTNER_INFO,
      variables() {
        return {
          templateID: this.$route.query.documentID,
        };
      },
      result({ data }) {
        this.partners = data.documents.map((doc) => doc.partner);
        this.documentTitle = data.documents[0]?.template?.title;
      },
    },
  },
};
</script>
