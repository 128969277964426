import gql from "graphql-tag";

//add create default finance template and default invoice template
export const CREATE_CLIENT = gql`
  mutation CreateClient($client: clients_insert_input!) {
    insert_clients_one(object: $client) {
      id
      name
      address {
        address1
        address2
        address3
        city
        state
        postalCode
      }
      phone
      ageRequirement
      federalTaxID
      isRecruiting
      active
      businessType
      insuranceSetting
    }
  }
`;

export const ADD_MANAGERS_TO_CLIENT = gql`
  mutation addManagersToClient($managers: [clientManagers_insert_input!]!) {
    insert: insert_clientManagers(objects: $managers) {
      affected_rows
      returning {
        clientID
      }
    }
  }
`;

export const UPDATE_CLIENT_DETAILS = gql`
  mutation UpdateClientDetails($id: uuid!, $client: clients_set_input!) {
    #      update_addresses_by_pk(pk_columns: {id: $addressId}, _set: $address) {
    #
    #      }
    update_clients_by_pk(pk_columns: { id: $id }, _set: $client) {
      id
      name
      address {
        id
        address1
        address2
        address3
        city
        state
        postalCode
      }
      phone
      ageRequirement
      federalTaxID
      isRecruiting
      active
      businessType
      insuranceSetting
    }
  }
`;

export const REMOVE_DOCUMENT_FROM_CLIENT = gql`
  mutation RemoveDocumentFromClient($id: uuid!) {
    update_documentTemplates_by_pk(pk_columns: { id: $id }, _set: { clientID: null }) {
      id
    }
  }
`;

// ##### Contacts
// mutation MyMutation3 ($address1:address1, $address2: address2, $city: city, $county: country, $postalCode: postalCode, $state: state) {
export const INSERT_CLIENT_CONTACT_ADDRESS = gql`
  mutation InsertClientContactAddress($address1: String, $address2: String, $city: String, $state: String, $postalCode: String, $country: String) {
    insert_addresses(objects: { address1: $address1, address2: $address2, city: $city, state: $state, postalCode: $postalCode, country: $country }) {
      returning {
        id
      }
    }
  }
`;
// update_addresses(where: {id: {_eq: ""}}, _set: {address1: "address1"})
export const UPDATE_CLIENT_CONTACT_ADDRESS = gql`
  mutation UpdateClientContactAddress($id: uuid, $objects: addresses_set_input) {
    update_addresses(where: { id: { _eq: $id } }, _set: $objects) {
      affected_rows
    }
  }
`;

// update_contacts(where: {id: {_eq: ""}}, _set: {address1: "address1"})
// export const UPDATE_CLIENT_CONTACT_USER = gql`
//   mutation UpdateClientContact($userID: uuid, $contactID: uuid, $roleID: uuid, $objects: contacts_set_input) {
//     update_contacts(where: { id: { _eq: $contactID } }, _set: $objects) {
//       affected_rows
//     }
//
//     update_users(where: { id: { _eq: $userID } }, _set: { disabled: false, roleID: $roleID }) {
//       affected_rows
//     }
//   }
// `;

export const UPDATE_CLIENT_CONTACT_ADDRESS_NULL = gql`
  mutation UpdateClientContactNull($contactID: uuid, $objects: contacts_set_input) {
    update_contacts(where: { id: { _eq: $contactID } }, _set: $objects) {
      affected_rows
    }
  }
`;

// export const UPDATE_CLIENT_CONTACT = gql`
//   mutation UpdateClientContact($userID: uuid, $contactID: uuid, $objects: contacts_set_input) {
//     update_contacts(where: { id: { _eq: $contactID } }, _set: $objects) {
//       affected_rows
//     }
//   }
// `;
// export const INSERT_CLIENT_CONTACT = gql`
//   mutation InsertClientContact($email: String, $firstName: String, $lastName: String, $phone: String, $clientID: uuid, $addressID: uuid) {
//     insert_contacts(objects: { addressID: $addressID, email: $email, firstName: $firstName, lastName: $lastName, phone: $phone, clientID: $clientID }) {
//       returning {
//         id
//       }
//     }
//   }
// `;

/*
export const INSERT_CLIENT_CONTACT_ADDRESS = gql`
    mutation InsertClientContactAddress(
        $objects: [addresses_insert_input!]!
    ) {
        insert_addresses(
            objects: $objects
         ) {
            returning {
                id
            }
        }
    }

export const INSERT_CLIENT_CONTACT_ADDRESS = gql`
    mutation InsertClientContactAddress(
        $address1: String,
        $address2: String,
        $city: String,
        $state: String,
        $postalCode: String,
        $country: String,
    ) {
        insert_addresses(
            objects: {
                address1: $address1,
                address2: $address2,
                city: $city,
                state: $state,
                postalCode: $postalCode,
                country: $country
            }
        ) {
            returning {
                id
            }
        }
    }
`;

export const UPDATE_CLIENT_CONTACT = gql`
    mutation UpdateClientContact(
        $id: uuid,
        $addressID: uuid,
        $contactID: uuid,
        $roleID: uuid,
        $email: String,
        $firstName: String,
        $lastName: String,
        $phone: String
    ) {
    update_contacts(where: {id: {_eq: $contactID}},
        _set: {
            addressID: $addressID,
            email: $email,
            firstName: $firstName,
            lastName: $lastName,
            phone: $phone
        })
    {
    affected_rows
    }

    update_users(where: {id: {_eq: $id}},
        _set: {
            roleID: $roleID
        })
    {
        affected_rows
    }


  }
*/
// ##### End Contacts

// ##### User
export const DELETE_USER = gql`
  mutation DeleteUser($id: uuid) {
    delete_users(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export const DISABLE_USER = gql`
  mutation DisableUser($id: uuid) {
    update_users(where: { id: { _eq: $id } }, _set: { disabled: true }) {
      affected_rows
    }
  }
`;
// ##### User

// ##### Delete Contact

// ##### End Contact

// ##### Address
export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: uuid) {
    delete_addresses(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($address: addresses_insert_input!) {
    insert_addresses_one(object: $address) {
      id
    }
  }
`;

export const UPSERT_ADDRESS = gql`
  mutation UpsertAddress($address: addresses_insert_input!) {
    insert_addresses_one(object: $address, on_conflict: { constraint: address_pkey, update_columns: [address1, address2, address3, city, state, postalCode, country] }) {
      id
    }
  }
`;
// ##### End Address

export const CREATE_FINANCE_TEMPLATE = gql`
  mutation createFinanceTemplate($financeTemplate: financeTemplates_insert_input!) {
    insert_financeTemplates_one(object: $financeTemplate) {
      id
    }
  }
`;

export const SEND_SMS = gql`
  mutation SendSMS($objects: [communicationsText_insert_input!]!) {
    insert_communicationsText(objects: $objects) {
      affected_rows
    }
  }
`;
export const SEND_EMAIL = gql`
  mutation SendEmail($objects: [communicationsEmail_insert_input!]!) {
    insert_communicationsEmail(objects: $objects) {
      affected_rows
    }
  }
`;

export const SET_CLIENT_COLOR = gql`
  mutation SetClientConfigColor($id: uuid, $color: String) {
    update_clients(where: { id: { _eq: $id } }, _set: { color: $color }) {
      returning {
        color
      }
    }
  }
`;

export const SET_CLIENT_LOGO = gql`
  mutation SetClientConfigLogo($id: uuid, $logo: String) {
    update_clients(where: { id: { _eq: $id } }, _set: { logo: $logo }) {
      returning {
        id
      }
    }
  }
`;

export const SET_CLIENT_LOCATION_LOGO = gql`
  mutation SetClientConfigLogo($id: uuid, $logo: String) {
    update_clientLocations(where: { id: { _eq: $id } }, _set: { logo: $logo }) {
      returning {
        id
      }
    }
  }
`;

export const SET_CLIENT_LOCATION_DELIVERY_AREA_MAP = gql`
  mutation SetClientLocationDeliveryAreaMap($id: uuid, $map: uuid) {
    update_clientLocations(where: { id: { _eq: $id } }, _set: { deliveryAreaMap: $map }) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_PAYMENT_SETTINGS = gql`
  mutation createPaymentSettings($payment: paymentSettings_insert_input!) {
    insert_paymentSettings_one(object: $payment) {
      id
    }
  }
`;

export const UPDATE_PAYMENT_SETTINGS = gql`
  mutation updatePaymentSettings($payment: paymentSettings_set_input, $id: uuid!) {
    update_paymentSettings_by_pk(pk_columns: { id: $id }, _set: $payment) {
      id
    }
  }
`;

export const ADD_PAYPERIOD_TO_FINANCE_TEMPLATE = gql`
  mutation updateFinanceTemplateWithPayperiod($payPeriodID: uuid, $id: uuid!) {
    update_financeTemplates_by_pk(pk_columns: { id: $id }, _set: { payPeriodID: $payPeriodID }) {
      id
    }
  }
`;

export const INSERT_INHERITED_OVERRIDES_DDI_DOCUMENT = gql`
  mutation insertInheritedOverridesDocuments($docs: [documentTemplateOverrides_insert_input!]!) {
    insert_documentTemplateOverrides(objects: $docs) {
      affected_rows
    }
  }
`;

export const INSERT_RECRUITMENT_DAYS = gql`
  mutation insertRecruitmentDays($timeperiod: timePeriod_insert_input!) {
    insert_timePeriod_one(object: $timeperiod) {
      id
    }
  }
`;

export const INSERT_HEADCOUNT_REQUEST = gql`
  mutation insertHeadCountRequest($object: clientLocationRequests_insert_input!) {
    insert_clientLocationRequests_one(object: $object, on_conflict: { constraint: locationRequest_pkey, update_columns: [requestedBy, requestDate, count] }) {
      id
    }
  }
`;

export const UPDATE_HEADCOUNT_REQUEST = gql`
  mutation updateHeadCountRequest($id: uuid!, $count: Int, $requestedBy: String, $requestDate: date) {
    update_clientLocationRequests_by_pk(pk_columns: { id: $id }, _set: {count: $count, requestedBy: $requestedBy, requestDate: $requestDate}) {
      id
    }
  }
`;